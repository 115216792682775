exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proc-2017-js": () => import("./../../../src/pages/proc2017.js" /* webpackChunkName: "component---src-pages-proc-2017-js" */),
  "component---src-pages-proc-2019-js": () => import("./../../../src/pages/proc2019.js" /* webpackChunkName: "component---src-pages-proc-2019-js" */),
  "component---src-pages-proc-2021-js": () => import("./../../../src/pages/proc2021.js" /* webpackChunkName: "component---src-pages-proc-2021-js" */),
  "component---src-pages-research-0-js": () => import("./../../../src/pages/research/0.js" /* webpackChunkName: "component---src-pages-research-0-js" */),
  "component---src-pages-research-1-js": () => import("./../../../src/pages/research/1.js" /* webpackChunkName: "component---src-pages-research-1-js" */),
  "component---src-pages-research-2-js": () => import("./../../../src/pages/research/2.js" /* webpackChunkName: "component---src-pages-research-2-js" */),
  "component---src-pages-research-3-js": () => import("./../../../src/pages/research/3.js" /* webpackChunkName: "component---src-pages-research-3-js" */),
  "component---src-pages-research-4-js": () => import("./../../../src/pages/research/4.js" /* webpackChunkName: "component---src-pages-research-4-js" */),
  "component---src-pages-research-5-js": () => import("./../../../src/pages/research/5.js" /* webpackChunkName: "component---src-pages-research-5-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

